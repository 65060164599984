import React from "react";

import Layout from "../components/Layout";
import { CenterLayout } from "../components/styled";

const NotFoundPage = () => (
    <Layout title="404: Not found">
        <CenterLayout>
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </CenterLayout>
    </Layout>
);

export default NotFoundPage;
